const testimonials = [
	{
		showOn: {
			Home: true,
			EventRoute: true,
			EventNavigation: true,
			EventSail: false,
			ClubOrienteering: false,
			ClubSailing: false
		},
		by: 'Jesper Valbak',
		from: 'Nordic Extreme Marathon',
		event: null,
		link: null,
		quote: 'Thanks for your help again this year. We have been extremely pleased with GPS tracking. There has been tremendous response from the participants who really feel we have opened a whole new world through the system. It worked flawlessly and the support from you was super! A few comments from fans:<br>' +
		'<small>' +
		'Very cool idea with GPS, so the audience as well as those at home in the living rooms could follow. But I\'ve also used it after the race to just studying where I could have chosen an easier path. Thanks for a great experience.<br>' +
		'A big thumps up for GPS tracking! It was enjoyable to follow, learn some new tricks and have a little fun of those who in the heat of battle ended astray while I could sit in the living room and be the wise man. Hope for the same next year!' +
		'</small>'
	},
	{
		showOn: {
			Home: false,
			EventRoute: false,
			EventNavigation: false,
			EventSail: false,
			ClubOrienteering: false,
			ClubSailing: true
		},
		by: 'Gaute Stakston',
		from: 'Board Member of Sandefjord Seilforening',
		event: null,
		link: null,
		quote: 'I would also like to say that our tracking system has been very well received in the club. Its used in our H-Boat class with weekly races and with our Optimist sailors also.'
	},
	{
		showOn: {
			Home: false,
			EventRoute: false,
			EventNavigation: false,
			EventSail: true,
			ClubOrienteering: false,
			ClubSailing: true
		},
		by: 'Alexander Gerstner',
		from: 'Bodensee Rund Um, Sponsor and Event Management',
		quote: 'Today a sailor of the class "30m2 Schärenkreuzer" was talking to me about this years Bodensee RUND UM. He was totally enthusiastic about this year’s "brilliant Live Tracking 2015". It was intuitive, stable and reliable and showed everything that an analytical sailor wants to see. The help function explained everything very well!'
	},
	{
		showOn: {
			Home: false,
			EventRoute: false,
			EventNavigation: false,
			EventSail: true,
			ClubOrienteering: false,
			ClubSailing: true
		},
		by: 'Lynn M. Lynch',
		from: 'Kite Foil Gold Cup, Race Director St. Francis Yacht Club',
		quote: 'Everything worked out great and people were very happy with the tracking program.'
	},
	{
		showOn: {
			Home: true,
			EventRoute: true,
			EventNavigation: true,
			EventSail: false,
			ClubOrienteering: true,
			ClubSailing: false
		},
		by: 'Per Forsberg',
		from: 'TV and event commentator',
		event: null,
		link: null,
		quote: 'Live tracking by TracTrac was a great success. I am convinced that live tracking is a necessary component of future live broadcasts.'
	},
	{
		showOn: {
			Home: true,
			EventRoute: false,
			EventNavigation: false,
			EventSail: true,
			ClubOrienteering: false,
			ClubSailing: false
		},
		by: 'Nils-Rune Nilsson',
		from: 'Sandhamsregattan',
		event: null,
		link: null,
		quote: 'As users of your system we can confirm that it is outstandingly good and user friendly.<br>The winner of the Express Class (Ronny Gustavsson, Skumpan) made the following spontaneous remark:<br>' +
		'The tracking is revolutionary for sail racing. We can follow the race and so can our friends and family, it is a fantastic success.<br>' +
		'A great number of other sailors made similar remarks.'
	},
	{
		showOn: {
			Home: true,
			EventRoute: false,
			EventNavigation: false,
			EventSail: true,
			ClubOrienteering: false,
			ClubSailing: true
		},
		by: 'John Leslie',
		from: 'Lipton Cup',
		event: null,
		link: null,
		quote: 'The live and playback during the Lipton was hugely successful, I have had endless positive feedback from everyone. The playback feature was very interesting as we did this each evening on the big screen in the club, in conjunction with a couple of the top skippers. This was extremely popular.'
	},
	{
		showOn: {
			Home: false,
			EventRoute: false,
			EventNavigation: true,
			EventSail: false,
			ClubOrienteering: true,
			ClubSailing: false
		},
		by: 'Pernille Buch',
		from: 'Coach Team Vejle Orientering',
		event: null,
		link: null,
		quote: 'It is very easy to use and set up a training. Before TracTrac we had challenges with the analysis and evaluation of our trainings as it always took time before you have all the data uploaded and gathered. With live tracking it is fantastic that already when the last runner arrive we can see all we need.<br>' +
		'Everything can be prepared beforehand, so that when you are at the training you can basically just push a button and focus on looking at how the runners have been doing.'
	},
	{
		showOn: {
			Home: false,
			EventRoute: true,
			EventNavigation: true,
			EventSail: false,
			ClubOrienteering: true,
			ClubSailing: false
		},
		by: 'Johan Granqvist',
		from: 'SM C55',
		event: null,
		link: null,
		quote: 'Everything worked extremely well. Many thanks for the support!'
	},
	{
		showOn: {
			Home: true,
			EventRoute: false,
			EventNavigation: false,
			EventSail: true,
			ClubOrienteering: false,
			ClubSailing: true
		},
		by: 'Brent Beck',
		from: 'Hawaiian Airlines Liberty Challenge',
		quote: 'Overall a great experience. Spectators and Racers really enjoyed the tracking. You will probably be hearing from some Hawaiian and Panamanian race organizers who spoke to me about the technology. Our experience with TracTrac has been very good.'
	},
	{
		showOn: {
			Home: false,
			EventRoute: false,
			EventNavigation: false,
			EventSail: false,
			ClubOrienteering: false,
			ClubSailing: true
		},
		by: 'Tony Bishop',
		from: 'Club Secretary Queen Mary Sailing Club',
		event: null,
		link: null,
		quote: 'TracTrac has been a great advance for Queen Mary Sailing Club. The calmness and efficiency to deliver tracking was superb, this was one of the key reasons we went with TracTrac. The TracTrac team has been very supportive and really passionate about making it work.'
	},
	{
		showOn: {
			Home: false,
			EventRoute: false,
			EventNavigation: false,
			EventSail: true,
			ClubOrienteering: false,
			ClubSailing: true
		},
		by: 'Amy Cada',
		from: 'Head of RC44 Media Team',
		event: null,
		link: null,
		quote: 'I thought the tracking was great, really useful for social media updates and our blog, especially with the split times at mark rounding\'s. It was also really great to be able to show in the hospitality tent, there was a lot of interest in that screen!'
	},
	{
		showOn: {
			Home: false,
			EventRoute: false,
			EventNavigation: false,
			EventSail: false,
			ClubOrienteering: false,
			ClubSailing: true
		},
		by: 'Jan Petter Gamborg-Nielsen',
		from: 'Daily Manager Oslo Yacht Club',
		event: null,
		link: null,
		quote: 'The system is a great service to our members and it gives a lot back to the sailors. We use them weekly at our regattas and the system is easy to use, nice to look at and is a great analytical tool for our sailors.<br>' +
		'We have a good relationship with TracTrac and look forward to further use.'
	},
	{
		showOn: {
			Home: true,
			EventRoute: false,
			EventNavigation: false,
			EventSail: true,
			ClubOrienteering: false,
			ClubSailing: false
		},
		by: 'Truls Rognerud',
		from: 'Sports Coordinator, Oslo seilforening',
		event: null,
		link: null,
		quote: 'Our mega event has come to and end and it was a success. Norwegian National Broadcasting watched the transmission and saw how valuable it is to use tracking to make good understandable visualisation of sailing. That was fun. We received a lot of praise for our coverage.'
	},
	{
		showOn: {
			Home: false,
			EventRoute: false,
			EventNavigation: false,
			EventSail: true,
			ClubOrienteering: false,
			ClubSailing: false
		},
		by: 'Hans Ramne',
		from: 'Sailing Båstad',
		event: null,
		link: null,
		quote: 'The Champions of Champions event was fantastic. The tracking worked perfect. All the sailors were super happy.'
	},
	{
		showOn: {
			Home: true,
			EventRoute: false,
			EventNavigation: false,
			EventSail: true,
			ClubOrienteering: false,
			ClubSailing: false
		},
		by: 'Edwin Lodder',
		from: 'Owner of Sailing Management International',
		event: null,
		link: null,
		quote: 'I was very happy with using the TracTrac system during the Delta Lloyd North Sea Regatta and the Nacra 17 Worlds. I think the system worked very well during both events and we had a lot of people who viewed the tracking. The software is working very well and I was very happy with your support.'
	}
]

export default {
	getFor: function (name) {
		let arr = []
		for (let i = 0; i < testimonials.length; i++) {
			if (testimonials[i].showOn[name]) {
				arr.push(testimonials[i])
			}
		}
		return arr
	}
}
