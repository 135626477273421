<template>
	<section class="mainSlider">
		<div ref="slider" class="slider">
			<div class="slide video">
				<video autoplay muted playsinline>
					<source src="https://live.tractrac.com/web/video1.mp4" type="video/mp4">
					Your browser does not support the video tag.
				</video>
				<div class="slogan link" style="left: 35%; transform: translate(0%, -50%)" @click="showModal = true">Live tracking for sailing <svg height="40px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="#ffffff" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path></svg></div>
			</div>
			<div class="slide video">
				<video autoplay muted playsinline>
					<source src="https://live.tractrac.com/web/video2.2.mp4" type="video/mp4">
					Your browser does not support the video tag.
				</video>
				<div class="slogan" style="right: 30%; transform: translate(-1000%, -50%)">Live tracking for any hard-to-follow sport</div>
			</div>
			<div class="slide video">
				<video autoplay muted playsinline>
					<source src="https://live.tractrac.com/web/video3.mp4" type="video/mp4">
					Your browser does not support the video tag.
				</video>
				<div class="slogan link" style="left: 20%; transform: translate(-1000%, -50%)" @click="showModal = true">For athletes, fans, sponsors and organizers <svg height="40px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="#ffffff" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path></svg></div>
			</div>
			<div class="slide video">
				<video autoplay muted playsinline>
					<source src="https://live.tractrac.com/web/video4.2.mp4" type="video/mp4">
					Your browser does not support the video tag.
				</video>
				<div class="slogan" style="right: 40%; transform: translate(-1000%, -50%)">Reveal the decisive moments</div>
			</div>
			<div class="slide video">
				<video autoplay muted playsinline>
					<source src="https://live.tractrac.com/web/video5.mp4" type="video/mp4">
					Your browser does not support the video tag.
				</video>
				<div class="slogan link" style="left: 30%; transform: translate(-1000%, -50%)" @click="showModal = true">Events of any shape and size <svg height="40px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="#ffffff" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path></svg></div>
			</div>
		</div>
		<div class="sliderIndex">
			<div class="indicator" ref="indicator"></div>
			<div class="button" v-for="(button, index) in slides" :ref="'slide_' + index" :key="index" @click="selectSlide(index, true)"></div>
		</div>
		<div class="changeSlide previous" @click="selectSlide(currentIndex - 1, false)"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M231.293 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L70.393 256 251.092 74.87c4.686-4.686 4.686-12.284 0-16.971L231.293 38.1c-4.686-4.686-12.284-4.686-16.971 0L4.908 247.515c-4.686 4.686-4.686 12.284 0 16.971L214.322 473.9c4.687 4.686 12.285 4.686 16.971-.001z"></path></svg></div>
		<div class="changeSlide next" @click="selectSlide(currentIndex + 1, false)"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"></path></svg></div>
		<div class="getquote">
			<router-link to="/get-event"><div class="left">
				<div><span>for <strong>EVENT</strong>s</span><button class="normal">Get quote</button></div>
			</div></router-link>
			<router-link to="/get-club-sailing"><div class="right">
				<div><span>for <strong>CLUB</strong>s</span><button class="normal">Get quote</button></div>
			</div></router-link>
		</div>

		<ModalVideo v-if="showModal" @close="showModal = false" :url="'https://www.youtube.com/embed/hjSy_F6u9EA?feature=oembed&autoplay=1&rel=0'" />
	</section>
</template>

<script>
/*
How to add slides?

Add another div with the class slide.
div.slide needs two children

either a video tag or an img tag and a div with class slogan.

If you add a video make sure to also add class video with slide.

Thats it! No need to modify javascript or css.
*/

import ModalVideo from '@/components/ModalVideo.vue'

export default {
	name: 'MainSlider',
	props: {
		msg: String
	},
	components: {
		ModalVideo
	},
	data () {
		return {
			publicPath: process.env.BASE_URL,
			slides: [],
			currentIndex: 0,
			currentTimer: null,
			video: null,
			showModal: false
		}
	},
	created () {

	},
	mounted () {
		this.slides = this.$refs.slider.children
		this.prepNextSlide(this.slides[this.currentIndex])
	},
	methods: {
		selectSlide (index, fromIndex) {
			if (index === this.currentIndex) {
				return
			}

			var transformFrom = 'translateX(100%)'
			var transformTo = 'translateX(-100%)'
			var transformSloganFrom = 'translate(1000%, -50%)'
			var transformSloganTo = 'translate(-1000%, -50%)'
			var slidesCount = this.slides.length - 1

			if (index > slidesCount) {
				index = 0
			} else if (index < 0) {
				index = slidesCount
			}

			this.slides[index].classList.add('notransition') // Disable transitions
			if ((index === 0 && this.currentIndex === slidesCount && fromIndex) || (index === slidesCount && this.currentIndex === 0 && !fromIndex) || (index < this.currentIndex)) {
				transformFrom = 'translateX(-100%)'
				transformTo = 'translateX(100%)'
				transformSloganFrom = 'translate(-1000%, -50%)'
				transformSloganTo = 'translate(1000%, -50%)'
			}
			this.slides[index].style.transform = transformFrom
			// eslint-disable-next-line
			this.slides[index].offsetHeight // Keep this line, it makes the view redraw
			this.slides[index].getElementsByClassName('slogan')[0].style.transform = transformSloganFrom
			this.slides[index].classList.remove('notransition') // Re-enable transitions

			this.slides[this.currentIndex].style.transform = transformTo
			this.slides[index].style.transform = 'translateX(0%)'

			this.slides[this.currentIndex].getElementsByClassName('slogan')[0].style.transform = transformSloganTo
			this.slides[index].getElementsByClassName('slogan')[0].style.transform = 'translate(0%, -50%)'

			this.currentIndex = index
			this.$refs.indicator.style.left = (this.$refs['slide_' + this.currentIndex][0].offsetLeft + 4) + 'px'

			this.prepNextSlide(this.slides[this.currentIndex])
		},
		prepNextSlide (elem) {
			if (this.currentTimer !== null) {
				clearTimeout(this.currentTimer)
				this.currentTimer = null
			}
			if (this.video !== null) {
				this.video.pause()
				this.video.onended = null
			}
			if (elem.classList.contains('video')) {
				this.video = elem.getElementsByTagName('VIDEO')[0]
				this.video.currentTime = 0
				this.video.play()
				this.video.onended = () => {
					this.selectSlide(this.currentIndex + 1, false)
				}
			} else {
				this.currentTimer = setTimeout(() => {
					this.selectSlide(this.currentIndex + 1, false)
				}, 3000)
			}
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

section.mainSlider {
	width: 100%;
	height: 100vh;
	background-image: url(https://live.tractrac.com/web/landing-background.jpg);
	background-size: cover;
	background-position: center;
}

section.mainSlider .slider {
	overflow: hidden;
	position: relative;
	width: 100%;
	height: 100%;
}

section.mainSlider .slide {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	transform: translateX(110%);
	transition: transform .5s ease-in-out;
}

section.mainSlider .slide:first-child {
	transform: translateX(0);
}

section.mainSlider .slide:last-child {
	transform: translateX(-110%);
}

section.mainSlider .slide img,
section.mainSlider .slide video {
	/* Make video to at least 100% wide and tall */
	min-width: 100%;
	min-height: 100%;

	/* Setting width & height to auto prevents the browser from stretching or squishing the video */
	width: auto;
	height: auto;

	/* Center the video */
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

section.mainSlider .slide .slogan {
	font-size: 30px;
	color: rgb(255, 255, 255);
	position: absolute;
	top: 70%;
	transition: transform 1s ease-in-out .5s;
	transform: translate(0%, -50%);
	text-shadow: 0 0 2px rgba(0,0,0,0.5);
}

section.mainSlider .slide .slogan.link {
	cursor: pointer;
}

section.mainSlider .slide .slogan.link:hover {
	text-decoration: underline;
}

section.mainSlider .slide .slogan.link:hover svg {
	filter: drop-shadow(0 0 2px rgba(255, 255, 255, .5));
}

section.mainSlider .slide .slogan.link:hover svg path {
	fill:red;
}

section.mainSlider .slide .slogan svg {
	margin-left: 12px;
	vertical-align: middle;
	transition: fill 0.2s linear, filter 0.2s linear;
	filter: drop-shadow(0 0 2px rgba(0, 0, 0, .5));
}

section.mainSlider .slide .slogan a {
	color: rgb(255, 255, 255);
	text-decoration: none;
}

section.mainSlider .slide .slogan a:hover {
	text-decoration: underline;
}

section.mainSlider .sliderIndex {
	position: absolute;
	bottom: 140px;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
}

section.mainSlider .sliderIndex .button {
	border: 2px solid white;
	background: transparent;
	border-radius: 50%;
	width: 18px;
	height: 18px;
	margin: 0 6px;
	flex-basis: 0;
	flex-grow: 1;
	flex-shrink: 0;
	box-sizing: border-box;
	cursor: pointer;
}

section.mainSlider .sliderIndex .button:hover {
	background: rgba(255,255,255, 0.25);
}

section.mainSlider .sliderIndex .button,
section.mainSlider .sliderIndex .indicator {
	box-shadow: 0 0 3px rgba(0,0,0,0.3);
}

section.mainSlider .changeSlide svg,
section.mainSlider .slide .slogan {
	text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
}

section.mainSlider .sliderIndex .indicator {
	background: white;
	position: absolute;
	top: 50%;
	border-radius: 50%;
	width: 10px;
	height: 10px;
	transform: translateY(-50%);
	left: 10px;
	transition: left .5s ease-in-out;
}

section.mainSlider .changeSlide {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
	fill: rgba(255,255,255, 0.5)
}

section.mainSlider .changeSlide:hover {
	fill: rgba(255,255,255, 1)
}

section.mainSlider .changeSlide.previous {
	left: 40px;
}

section.mainSlider .changeSlide.next {
	right: 40px;
}

section.mainSlider .changeSlide.previous svg,
section.mainSlider .changeSlide.next svg {
	width: 18px;
}

section.mainSlider .getquote {
	width: 100%;
	height: 120px;
	position: absolute;
	bottom: 0;
	left: 0;
	display: flex;
	color: white;
}

section.mainSlider .getquote a,
section.mainSlider .getquote a div {
	height: 100%;
	flex-basis: 0;
	flex-grow: 1;
	flex-shrink: 0;
	position: relative;
	font-size: 28px;
	font-weight: 500;
	color: white;
	transition: background 0.3s linear;
}

section.mainSlider .getquote a .left {
	background: rgba(0, 90, 132, 0.8);
}

section.mainSlider .getquote a .right {
	background: rgba(0, 159, 194, 0.8);
}

section.mainSlider .getquote a:hover .left {
	background: rgba(0, 90, 132, 0.95);
}

section.mainSlider .getquote a:hover .right {
	background: rgba(0, 159, 194, 0.95);
}

section.mainSlider .getquote a div div {
	height: auto;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

section.mainSlider .getquote div div button {
	margin-left: 24px;
}

section.mainSlider .getquote div div span {
	vertical-align: middle;
}

@media only screen and (max-width: 640px) {

	section.mainSlider {
		height: calc(100vw * 0.5625);
		margin-top: 82px;
		position: relative;
	}

	section.mainSlider .slide img,
	section.mainSlider .slide video {
		width: 100vw;
		height: calc(100vw * 0.5625);
	}

	section.mainSlider .slide .slogan {
		font-size: 10px;
		top: 24px;
	}

	section.mainSlider .slide .slogan svg {
		height: 12px;
	}

	section.mainSlider .changeSlide.previous svg,
	section.mainSlider .changeSlide.next svg {
		width: 12px;
	}

	section.mainSlider .changeSlide.previous {
		left: 12px;
	}

	section.mainSlider .changeSlide.next {
		right: 12px;
	}

	section.mainSlider .getquote {
		height: 60px;
	}

	section.mainSlider .getquote a div {
		font-size: 12px;
	}

	section.mainSlider .sliderIndex {
		display: none;
	}

	section.mainSlider .getquote div div {
		width: 70%;
		text-align: center;
	}

	section.mainSlider .getquote div div span {
		display: block;
	}

	section.mainSlider .getquote div div button {
		margin-left: 0;
		margin-top: 3px;
	}
}

</style>
