export default {
	parse: function (name) {
		let obj = {}
		obj.name = name
		switch (name.toLowerCase()) {
		case 'adventure & multisport':
			obj.iconLarge = 'https://live.tractrac.com/web/adventure_256.png'
			obj.iconSmall = 'https://live.tractrac.com/web/adventure_64.png'
			obj.tutorialApp = 'https://live.tractrac.com/web/TracTrac_App_Tutorial_-_Orienteering.pdf'
			obj.tutorialWeb = 'https://live.tractrac.com/web/TracTrac_Web_Viewer_Tutorial_-_Orienteering.pdf'
			obj.class = 'adventure'
			obj.cover = 'https://live.tractrac.com/images/adventure.jpg'
			break
		case 'cycling':
			obj.iconLarge = 'https://live.tractrac.com/web/cycling_256.png'
			obj.iconSmall = 'https://live.tractrac.com/web/cycling_64.png'
			obj.tutorialApp = 'https://live.tractrac.com/web/TracTrac_App_Tutorial_-_Route_Sport.pdf'
			obj.tutorialWeb = 'https://live.tractrac.com/web/TracTrac_Web_Viewer_Tutorial_-_Route_Sport.pdf'
			obj.class = 'cycling'
			obj.cover = 'https://live.tractrac.com/images/cycling.jpg'
			break
		case 'equestrian':
			obj.iconLarge = 'https://live.tractrac.com/web/equestrian_256.png'
			obj.iconSmall = 'https://live.tractrac.com/web/equestrian_64.png'
			obj.tutorialApp = 'https://live.tractrac.com/web/TracTrac_App_Tutorial_-_Route_Sport.pdf'
			obj.tutorialWeb = 'https://live.tractrac.com/web/TracTrac_Web_Viewer_Tutorial_-_Route_Sport.pdf'
			obj.class = 'equestrian'
			obj.cover = 'https://live.tractrac.com/images/equestrian.jpg'
			break
		case 'kayak':
			obj.iconLarge = 'https://live.tractrac.com/web/kayak_256.png'
			obj.iconSmall = 'https://live.tractrac.com/web/kayak_64.png'
			obj.tutorialApp = 'https://live.tractrac.com/web/2020 Mobile app - quick guide.pdf'
			obj.tutorialWeb = 'https://live.tractrac.com/web/TracTrac_Web_Viewer_Tutorial_-_Sailing.pdf'
			obj.class = 'kayak'
			obj.cover = 'https://live.tractrac.com/images/kayak.jpg'
			break
		case 'kite surfing':
			obj.iconLarge = 'https://live.tractrac.com/web/kite_surfing_256.png'
			obj.iconSmall = 'https://live.tractrac.com/web/kite_surfing_64.png'
			obj.tutorialApp = 'https://live.tractrac.com/web/2020 Mobile app - quick guide.pdf'
			obj.tutorialWeb = 'https://live.tractrac.com/web/TracTrac_Web_Viewer_Tutorial_-_Sailing.pdf'
			obj.class = 'kite_surfing'
			obj.cover = 'https://live.tractrac.com/images/kite.jpg'
			break
		case 'mountainbike':
			obj.iconLarge = 'https://live.tractrac.com/web/mountain_biking_256.png'
			obj.iconSmall = 'https://live.tractrac.com/web/mountain_biking_64.png'
			obj.tutorialApp = 'https://live.tractrac.com/web/TracTrac_App_Tutorial_-_Route_Sport.pdf'
			obj.tutorialWeb = 'https://live.tractrac.com/web/TracTrac_Web_Viewer_Tutorial_-_Route_Sport.pdf'
			obj.class = 'mountain_biking'
			obj.cover = 'https://live.tractrac.com/images/mountainbike.jpg'
			break
		case 'orientering':
		case 'orienteering':
			obj.iconLarge = 'https://live.tractrac.com/web/orienteering_256.png'
			obj.iconSmall = 'https://live.tractrac.com/web/orienteering_64.png'
			obj.tutorialApp = 'https://live.tractrac.com/web/TracTrac_App_Tutorial_-_Orienteering.pdf'
			obj.tutorialAppVideo = 'https://youtu.be/XUt7J8w2AUQ'
			obj.tutorialWeb = 'https://live.tractrac.com/web/TracTrac_Web_Viewer_Tutorial_-_Orienteering.pdf'
			obj.tutorialWebVideo = 'https://youtu.be/-5jJbWoOGVU'
			obj.class = 'orienteering'
			obj.cover = 'https://live.tractrac.com/images/orienteering.jpg'
			break
		case 'paddling':
			obj.iconLarge = 'https://live.tractrac.com/web/paddling_256.png'
			obj.iconSmall = 'https://live.tractrac.com/web/paddling_64.png'
			obj.tutorialApp = 'https://live.tractrac.com/web/2020 Mobile app - quick guide.pdf'
			obj.tutorialWeb = 'https://live.tractrac.com/web/TracTrac_Web_Viewer_Tutorial_-_Sailing.pdf'
			obj.class = 'paddling'
			obj.cover = 'https://live.tractrac.com/images/paddling.jpg'
			break
		case 'rowing':
			obj.iconLarge = 'https://live.tractrac.com/web/rowing_256.png'
			obj.iconSmall = 'https://live.tractrac.com/web/rowing_64.png'
			obj.tutorialApp = 'https://live.tractrac.com/web/2020 Mobile app - quick guide.pdf'
			obj.tutorialWeb = 'https://live.tractrac.com/web/TracTrac_Web_Viewer_Tutorial_-_Sailing.pdf'
			obj.class = 'rowing'
			obj.cover = 'https://live.tractrac.com/images/rowing.jpg'
			break
		case 'running':
			obj.iconLarge = 'https://live.tractrac.com/web/running_256.png'
			obj.iconSmall = 'https://live.tractrac.com/web/running_64.png'
			obj.tutorialApp = 'https://live.tractrac.com/web/TracTrac_App_Tutorial_-_Route_Sport.pdf'
			obj.tutorialWeb = 'https://live.tractrac.com/web/TracTrac_Web_Viewer_Tutorial_-_Route_Sport.pdf'
			obj.class = 'running'
			obj.cover = 'https://live.tractrac.com/images/running.jpg'
			break
		case 'sailing':
			obj.iconLarge = 'https://live.tractrac.com/web/sailing_256.png'
			obj.iconSmall = 'https://live.tractrac.com/web/sailing_64.png'
			obj.tutorialApp = 'https://live.tractrac.com/web/2020 Mobile app - quick guide.pdf'
			obj.tutorialWeb = 'https://live.tractrac.com/web/TracTrac_Web_Viewer_Tutorial_-_Sailing.pdf'
			obj.class = 'sailing'
			obj.cover = 'https://live.tractrac.com/images/sailing.jpg'
			break
		case 'skiing':
			obj.iconLarge = 'https://live.tractrac.com/web/skiing_cross_country_256.png'
			obj.iconSmall = 'https://live.tractrac.com/web/skiing_cross_country_64.png'
			obj.tutorialApp = 'https://live.tractrac.com/web/2020 Mobile app - quick guide.pdf'
			obj.tutorialWeb = 'https://live.tractrac.com/web/TracTrac_Web_Viewer_Tutorial_-_Sailing.pdf'
			obj.class = 'skiing_cross_country'
			obj.cover = 'https://live.tractrac.com/images/skiing.jpg'
			break
		case 'swimming':
			obj.iconLarge = 'https://live.tractrac.com/web/swiming_256.png'
			obj.iconSmall = 'https://live.tractrac.com/web/swiming_64.png'
			obj.tutorialApp = 'https://live.tractrac.com/web/2020 Mobile app - quick guide.pdf'
			obj.tutorialWeb = 'https://live.tractrac.com/web/TracTrac_Web_Viewer_Tutorial_-_Sailing.pdf'
			obj.class = 'swimming'
			obj.cover = 'https://live.tractrac.com/images/swimming.jpg'
			break
		case 'triathlon':
			obj.iconLarge = 'https://live.tractrac.com/web/triathlon_256.png'
			obj.iconSmall = 'https://live.tractrac.com/web/triathlon_64.png'
			obj.tutorialApp = 'https://live.tractrac.com/web/TracTrac_App_Tutorial_-_Route_Sport.pdf'
			obj.tutorialWeb = 'https://live.tractrac.com/web/TracTrac_Web_Viewer_Tutorial_-_Route_Sport.pdf'
			obj.class = 'triathlon'
			obj.cover = 'https://live.tractrac.com/images/triathlon.jpg'
			break
		default:
			obj.iconLarge = 'https://live.tractrac.com/web/default_256.png'
			obj.iconSmall = 'https://live.tractrac.com/web/default_64.png'
			obj.tutorialApp = 'https://live.tractrac.com/web/2020 Mobile app - quick guide.pdf'
			obj.tutorialWeb = 'https://live.tractrac.com/web/TracTrac_Web_Viewer_Tutorial_-_Sailing.pdf'
			obj.class = 'default'
			obj.cover = 'https://live.tractrac.com/images/adventure.jpg'
			break
		}
		return obj
	}
}
