<template>
	<section class="trackRecord">
		<div class="gradientOverlay"></div>
		<div class="container">
			<h1 class="text-white">Track record</h1>
			<div class="columns">
				<div class="column">
					<h2>{{ years }}</h2>
					<p>Years</p>
				</div>
				<div class="column">
					<h2>{{ countries }}</h2>
					<p>Countries</p>
				</div>
				<div class="column">
					<h2>{{ races }}</h2>
					<p>Races</p>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'TrackRecord',
	data () {
		return {
			years: '',
			countries: '',
			races: ''
		}
	},
	created () {
		this.getTrackRecord()
	},
	methods: {
		getTrackRecord () {
			this.$http.get('https://em.club.tractrac.com/web_counters.json').then((response) => {
				if (response.status === 200) {
					this.years = response.data.year
					this.countries = response.data.countries
					this.races = response.data.races
				}
			})
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

section.trackRecord {
	background-image: url(https://live.tractrac.com/web/viewer-sailing.jpg);
	background-size: cover;
	background-position: center;
	color: white;
	text-align: center;
	position: relative;
}

section.trackRecord .container {
	padding: 48px 0;
}

section.trackRecord .container .columns {
	display: flex;
}

section.trackRecord .container h1 {
	font-size: 30px;
	font-weight: 300;
	margin-top: 0;
}

section.trackRecord .container .columns .column {
	flex-grow: 1;
	flex-shrink: 0;
	flex-basis: 0;
}

section.trackRecord .container .columns .column h2 {
	font-size: 48px;
	font-weight: 400;
	margin: 0 0 12px 0;
}

section.trackRecord .container .columns .column p {
	font-size: 16px;
	font-weight: 500;
	text-transform: uppercase;
	margin: 0;
}

@media only screen and (max-width: 640px) {

	section.trackRecord .container {
		padding: 24px 0;
	}

	section.trackRecord .container h1 {
		font-size: 24px;
	}

	section.trackRecord .container .columns .column h2 {
		font-size: 24px;
	}

	section.trackRecord .container .columns .column p {
		font-size: 12px;
	}
}

</style>
