<template>
	<section class="testimonials">
			<div ref="slider" class="slider">
				<div class="slide" v-for="(item, index) in list" :key="index">
					<h2>{{ item.by }}, {{ item.from }}</h2>
					<blockquote v-html="item.quote"></blockquote>
				</div>
			</div>
		<div class="sliderIndex">
			<div class="indicator" ref="indicator"></div>
			<div class="button" v-for="(button, index) in slides" :ref="'slide_' + index" :key="index" @click="selectSlide(index, true)"></div>
		</div>
		<div class="changeSlide previous" @click="selectSlide(currentIndex - 1, false)"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M231.293 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L70.393 256 251.092 74.87c4.686-4.686 4.686-12.284 0-16.971L231.293 38.1c-4.686-4.686-12.284-4.686-16.971 0L4.908 247.515c-4.686 4.686-4.686 12.284 0 16.971L214.322 473.9c4.687 4.686 12.285 4.686 16.971-.001z"></path></svg></div>
		<div class="changeSlide next" @click="selectSlide(currentIndex + 1, false)"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"></path></svg></div>
	</section>
</template>

<script>
export default {
	name: 'testimonials',
	props: ['list'],
	data () {
		return {
			publicPath: process.env.BASE_URL,
			slides: [],
			currentIndex: 0,
			currentTimer: null
		}
	},
	mounted () {
		this.slides = this.$refs.slider.children
		this.prepNextSlide(this.slides[this.currentIndex])
	},
	methods: {
		selectSlide (index, fromIndex) {
			if (index === this.currentIndex) {
				return
			}

			var transformFrom = 'translate(100%, -50%)'
			var transformTo = 'translate(-100%, -50%)'
			var slidesCount = this.slides.length - 1

			if (index > slidesCount) {
				index = 0
			} else if (index < 0) {
				index = slidesCount
			}

			this.slides[index].classList.add('notransition') // Disable transitions
			if ((index === 0 && this.currentIndex === slidesCount && fromIndex) || (index === slidesCount && this.currentIndex === 0 && !fromIndex) || (index < this.currentIndex)) {
				transformFrom = 'translate(-100%, -50%)'
				transformTo = 'translate(100%, -50%)'
			}
			this.slides[index].style.transform = transformFrom
			// eslint-disable-next-line
			this.slides[index].offsetHeight // Keep this line, it makes the view redraw
			this.slides[index].classList.remove('notransition') // Re-enable transitions

			this.slides[this.currentIndex].style.transform = transformTo
			this.slides[index].style.transform = 'translate(0%, -50%)'

			this.currentIndex = index
			this.$refs.indicator.style.left = (this.$refs['slide_' + this.currentIndex][0].offsetLeft + 4) + 'px'

			this.prepNextSlide(this.slides[this.currentIndex])
		},
		prepNextSlide (elem) {
			if (this.currentTimer !== null) {
				clearTimeout(this.currentTimer)
				this.currentTimer = null
			}

			// this.currentTimer = setTimeout(() => {
			// 	this.selectSlide(this.currentIndex + 1, false)
			// }, 5000);
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

$link: #333;
$hover: #e74c3c;
$blueDark: rgba(0, 90, 132, 1);
$blueLight: rgba(0, 159, 194, 1);
$green: #63b323;
$grey: #878787;

section.testimonials {
	position: relative;
	background: #f4f4f4;
	width: 100%;
	height: 320px;
}

section.testimonials .slider {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

section.testimonials .slide {
	position: absolute;
	top: 50%;
	bottom: 0;
	width: 100%;
	overflow: hidden;
	transform: translate(110%, -50%);
	transition: transform .7s ease-in-out;
	text-align: center;
	color: $blueDark;
	box-sizing: border-box;
	padding: 0 10%;
}

section.testimonials .slide h2 {
	color: $blueDark;
	margin-top: 0;
	font-weight: 300;
}

section.testimonials .slide blockquote {
	font-style: italic;
}

section.testimonials .slide:first-child {
	transform: translate(0, -50%);
}

section.testimonials .slide:last-child {
	transform: translate(-110%, -50%);
}

section.testimonials .sliderIndex {
	position: absolute;
	bottom: 32px;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
}

section.testimonials .sliderIndex .button {
	border: 2px solid $grey;
	background: transparent;
	border-radius: 50%;
	width: 18px;
	height: 18px;
	margin: 0 6px;
	flex-basis: 0;
	flex-grow: 1;
	flex-shrink: 0;
	box-sizing: border-box;
	cursor: pointer;
}

section.testimonials .sliderIndex .button:hover {
	background: rgba(157,157,157, 0.25);
}

section.testimonials .sliderIndex .indicator {
	background: $grey;
	position: absolute;
	top: 50%;
	border-radius: 50%;
	width: 10px;
	height: 10px;
	transform: translateY(-50%);
	left: 10px;
	transition: left .5s ease-in-out;
}

section.testimonials .changeSlide {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
	fill: rgba(157,157,157, 0.5)
}

section.testimonials .changeSlide:hover {
	fill: rgba(157,157,157, 1)
}

section.testimonials .changeSlide.previous {
	left: 40px;
}

section.testimonials .changeSlide.next {
	right: 40px;
}

section.testimonials .changeSlide.previous svg,
section.testimonials .changeSlide.next svg {
	width: 18px;
}

@media only screen and (max-width: 640px) {

	section.testimonials {
		height: calc(100vw * 0.5625);
		margin-top: 82px;
		position: relative;
	}

	section.testimonials .slide h2 {
		font-size: 16px;
	}

	section.testimonials .slide blockquote {
		font-size: 10px;
		margin: 0;
	}

	section.testimonials .changeSlide.previous svg,
	section.testimonials .changeSlide.next svg {
		width: 12px;
	}

	section.testimonials .changeSlide.previous {
		left: 12px;
	}

	section.testimonials .changeSlide.next {
		right: 12px;
	}

	section.testimonials .sliderIndex {
		display: none;
	}
}

</style>
