<template>
	<main class="home">

		<MainSlider />

		<section class="events">
			<div class="list event">
				<h2>Events<span>now</span></h2>
				<div class="more"><router-link to="/events-list">All events <svg width="20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M340.485 366l99.03-99.029c4.686-4.686 4.686-12.284 0-16.971l-99.03-99.029c-7.56-7.56-20.485-2.206-20.485 8.485v71.03H12c-6.627 0-12 5.373-12 12v32c0 6.627 5.373 12 12 12h308v71.03c0 10.689 12.926 16.043 20.485 8.484z"></path></svg></router-link></div>
				<div class="activity" v-for="event in eventsList" :key="event.id">
					<div class="logo"><img :src="'https://live.tractrac.com/' + event.logo" alt="Event logo" /></div>
					<div class="info">
						<h6><img class="type" :src="event.sport.iconSmall" :alt="event.sport.name" :class="event.sport.class" /><router-link :to="'/event-page/' + event.database + '/' + event.id"><span v-html="event.name"></span></router-link></h6>
						<p class="fecha">{{ event.startTime.replace("-", " ") }} - {{ event.endTime.replace("-", " ") }} {{ event.city }} </p>
					</div>
				</div>
			</div>
			<div class="list club">
				<h2>Club activities<span>now</span></h2>
				<div class="more"><router-link to="/clubs-list">All clubs <svg width="20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M340.485 366l99.03-99.029c4.686-4.686 4.686-12.284 0-16.971l-99.03-99.029c-7.56-7.56-20.485-2.206-20.485 8.485v71.03H12c-6.627 0-12 5.373-12 12v32c0 6.627 5.373 12 12 12h308v71.03c0 10.689 12.926 16.043 20.485 8.484z"></path></svg></router-link></div>
				<div class="activity" v-for="club in clubsList" :key="club.ClubID">
					<div class="logo"><img :src="club.logopath" alt="Club logo" /></div>
					<div class="info">
						<h6><img class="type" :src="club.sport.iconSmall" :alt="club.sport.name" :class="club.sport.class" /><router-link :to="'/club-page/' + club.ClubID + '/' + club.Organization.replace('/', ' ')"><span v-html="club.name"></span></router-link> <span class="status" :class="club.RaceStatus.toLowerCase()">{{ club.RaceStatus }}</span></h6>
						<p class="fecha">{{ club.dates }} {{ club.name2 }}</p>
					</div>
				</div>
			</div>
		</section>

		<TrackRecord />

		<Testimonials :list="testimonials" />

		<section class="map">
			<h1>Current events and club activities</h1>
			<p>Events: <img src="https://live.tractrac.com/web/map_event_past.png" alt="Map icon" /> recent <img src="https://live.tractrac.com/web/map_event_current.png" alt="Map icon" /> current <img src="https://live.tractrac.com/web/map_event_future.png" alt="Map icon" /> coming soon</p>
			<p>Club races: <img src="https://live.tractrac.com/web/map_club_past.png" alt="Map icon" /> recent <img src="https://live.tractrac.com/web/map_club_current.png" alt="Map icon" /> current <img src="https://live.tractrac.com/web/map_club_future.png" alt="Map icon" /> coming soon</p>
			<div ref="map" class="map"></div>
		</section>

		<BecomePartner />

	</main>
</template>

<script>

// @ is an alias to /src
import constants from '@/helpers/constants'
import MainSlider from '@/components/MainSlider.vue'
import TrackRecord from '@/components/TrackRecord.vue'
import BecomePartner from '@/components/BecomePartner.vue'
import Testimonials from '@/components/Testimonials.vue'
import TestimonialsList from '@/helpers/testimonials'
import GoogleMapsLoader from 'google-maps'
import Sports from '@/helpers/sports'

function replaceAll (str, find, replace) {
	return str.replace(new RegExp(find, 'g'), replace)
}

export default {
	name: 'home',
	components: {
		MainSlider,
		TrackRecord,
		BecomePartner,
		Testimonials
	},
	data () {
		return {
			publicPath: process.env.BASE_URL,
			map: null,
			events: [],
			clubs: [],
			google: null,
			testimonials: TestimonialsList.getFor('Home'),
			showModal: false
		}
	},
	created () {
		this.$http.get(`${constants.REST_API_URL}/currentevents.json`).then((response) => {
			if (response.status === 200) {
				for (let i = 0; i < response.data.events.length; i++) {
					response.data.events[i].sport = Sports.parse(response.data.events[i].type)
					response.data.events[i].name = replaceAll(response.data.events[i].name, '"', '')
					response.data.events[i].city = replaceAll(response.data.events[i].city, '"', '')
				}
				for (let i = 0; i < response.data.clubs.length; i++) {
					response.data.clubs[i].sport = Sports.parse(response.data.clubs[i].EventType)
				}
				this.events = response.data.events
				this.clubs = response.data.clubs

				if (this.google !== null) {
					this.addMapMarkers(this.events, this.google, true)
					this.addMapMarkers(this.clubs, this.google, false)
				}
			}
		})
	},
	mounted () {
		GoogleMapsLoader.KEY = 'AIzaSyABzKpr1S2Mqg_oxY-2aGSU-6_v4yp5iy0'
		GoogleMapsLoader.VERSION = 'weekly'
		GoogleMapsLoader.load((google) => {
			this.map = new google.maps.Map(this.$refs.map, {
				zoom: 2,
				scrollwheel: false,
				center: new google.maps.LatLng(28, 0),
				styles: [{ 'elementType': 'labels', 'stylers': [{ 'visibility': 'off' }] }, { 'featureType': 'administrative', 'elementType': 'geometry', 'stylers': [{ 'visibility': 'off' }] }, { 'featureType': 'administrative.land_parcel', 'stylers': [{ 'visibility': 'off' }] }, { 'featureType': 'administrative.neighborhood', 'stylers': [{ 'visibility': 'off' }] }, { 'featureType': 'poi', 'stylers': [{ 'visibility': 'off' }] }, { 'featureType': 'road', 'stylers': [{ 'visibility': 'off' }] }, { 'featureType': 'road', 'elementType': 'labels.icon', 'stylers': [{ 'visibility': 'off' }] }, { 'featureType': 'transit', 'stylers': [{ 'visibility': 'off' }] }],
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false
			})
			if (this.events.length > 0) {
				this.addMapMarkers(this.events, google, true)
			}
			if (this.clubs.length > 0) {
				this.addMapMarkers(this.clubs, google, false)
			}
			this.google = google
		})
	},
	methods: {
		addMapMarkers (locations, google, isEvent) {
			var shape = {
				coords: [1, 1, 1, 21, 27, 20, 27, 1],
				type: 'poly'
			}
			for (var i = 0; i < locations.length; i++) {
				let location = locations[i]; let map = this.map
				if (location.lat != null && location.lon != null) {
					var image = {
						url: 'https://live.tractrac.com/web/map_' + (isEvent ? 'event' : 'club') + '_' + location.map_visibility + '.png',
						size: new google.maps.Size(22, 34),
						origin: new google.maps.Point(0, 0),
						anchor: new google.maps.Point(11, 34)
					}

					var contentString = ''
					var infowindow = new google.maps.InfoWindow({
						content: contentString
					})

					var latLng = new google.maps.LatLng(location.lat, location.lon)
					var marker = new google.maps.Marker({
						position: latLng,
						map: this.map,
						icon: image,
						shape: shape,
						title: isEvent ? location.city + ' (' + location.country + ')' : location.name2
					})

					let popup = null
					if (isEvent) {
						popup = '<div class="gmaps-info">' +
									'<div class="picture">' +
										'<img src="https://www.tractrac.com/' + location.logo + '" alt="' + location.name + '">' +
									'</div>' +
									'<div class="content">' +
										'<h2>' + location.name + '</h2>' +
										'<p><img src="' + location.sport.iconSmall + '" alt="' + location.sport.name + '" class="' + location.sport.class + '"> ' + location.startTime + ' ' + location.endTime + '</p>' +
										'<p><a href="' + process.env.BASE_URL + 'event-page/' + location.database + '/' + location.id + '">see more</a></p>' +
									'</div>' +
								'</div>'
					} else {
						popup = '<div class="gmaps-info">' +
									'<div class="picture">' +
										'<img src="' + location.logopath + '" alt="' + location.EventName + '">' +
									'</div>' +
									'<div class="content">' +
										'<h2>' + location.RaceName + '</h2>' +
										'<p><img src="' + location.sport.iconSmall + '" alt="' + location.sport.name + '" class="' + location.sport.class + '"> ' + location.dates + '</p>' +
										'<p><a href="' + process.env.BASE_URL + 'club-page/' + location.ClubID + '/' + location.Organization + '">see more</a></p>' +
									'</div>' +
								'</div>'
					}

					google.maps.event.addListener(marker, 'click', function () {
						infowindow.setContent(popup)
						infowindow.open(map, this)
					})
				}
			}
		}
	},
	computed: {
		/*
			We're getting a list of 18 events closest to the current date but we only need 6. And we don't know which event in this list is closest to the current date.
			So we'll extract the index of that event and take 3 event sin the future from that event and two ini the past to get 6.
		*/
		eventsList () {
			if (this.events.length === 0) {
				return this.events
			}
			let sortedEvents = this.events.slice(0).sort((a, b) => (a.startTime > b.startTime) ? 1 : ((b.startTime > a.startTime) ? -1 : 0))

			const today = new Date()
			const goal = parseInt('' + today.getFullYear() + '' + String(today.getMonth() + 1).padStart(2, '0') + '' + String(today.getDate()).padStart(2, '0'))
			const current = sortedEvents.reduce((prev, curr) => Math.abs(parseInt(curr.startTime.replace(new RegExp('-', 'g'), '')) - goal) < Math.abs(parseInt(prev.startTime.replace(new RegExp('-', 'g'), '')) - goal) ? curr : prev)
			const index = sortedEvents.indexOf(current, 0)

			let end = index + 3
			let start = index - 3
			if (end > sortedEvents.length) {
				end = sortedEvents.length
				start = sortedEvents.length - 6
			}
			if (start <= 0) {
				start = 0
				end = 6
			}

			return sortedEvents.slice(start, end)
		},
		// we dont care as much of the club events ;)
		clubsList () {
			if (this.clubs.length > 0) {
				let length = this.clubs.length
				if (length > 6) length = 6
				return this.clubs.slice(0, length)
			}
			return this.clubs
		}
	}
}

</script>

<style scoped lang="scss">

$link: #333;
$hover: #e74c3c;
$blueDark: rgba(0, 90, 132, 1);
$blueLight: rgba(0, 159, 194, 1);
$green: #63b323;
$grey: #878787;

section.events {
	display: flex;
}

section.events .list {
	flex-grow: 1;
	flex-shrink: 0;
	flex-basis: 0;
	padding: 24px;
	box-sizing: border-box;
	position: relative;
}

section.events .list.event {
	background: #ffffff;
}

section.events .list.club {
	background: #e3edf2;
}

section.events .list h2 {
	font-weight: 300;
	color: $blueDark;
	font-size: 30px;
	margin: 0;
}

section.events .list h2 span {
	font-weight: 100;
	color: $blueLight;
	text-transform: uppercase;
	margin-left: 8px;
	font-size: 24px;
}

section.events .list .more {
	fill: $blueLight;
	position: absolute;
	top: 36px;
	right: 24px;
	text-transform: uppercase;
	font-size: 12px;
}

section.events .list .more a {
	color: $blueDark;
	text-decoration: none;
}

section.events .list .more a svg {
	vertical-align: middle;
	margin-left: 8px;
	margin-top: -2px;
}

section.events .list .activity {
	display: flex;
	position: relative;
	padding: 18px 0;
	box-sizing: border-box;
}

section.events .list .activity .logo {
	position: relative;
	flex-basis: 96px;
	flex-grow: 0;
	flex-shrink: 0;
}

section.events .list .activity .logo img {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: auto;
	height: auto;
	max-width: 80px;
	max-height: 40px;
}

section.events .list .activity .info {
	margin-left: 16px;
}

section.events .list .activity .info p {
	font-size: 12px;
	color: $grey;
	margin: 6px 0 0 0;
}

section.events .list .activity .info h6 {
	font-size: 16px;
	font-weight: 600;
	margin: 0;
}

section.events .list .activity .info h6 img {
	margin-bottom: -2px;
	margin-right: 16px;
}

section.events .list .activity .info h6 a {
	text-decoration: none;
	color: $link;
}

section.events .list .activity .info h6 a:hover {
	color: $hover;
}

section.events .list .activity .info h6 span.status {
	text-transform: uppercase;
	font-size: 13px;
	font-weight: 400;
	margin-left: 8px;
}

section.events .list .activity .info h6 span.status.live {
	color: $hover;
}

section.events .list .activity .info h6 span.status.replay {
	color: $blueLight;
}

section.events .list .activity .info h6 span.status.coming.up {
	color: $green;
}

section.map {
	text-align: center;
	padding: 48px 0 0 0;
}

section.map h1 {
	font-size: 30px;
	font-weight: 300;
	margin-top: 0;
	color: $blueDark;
}

section.map p img {
	vertical-align: middle;
	margin: 0 3px 0 12px;
	width: 15px;
	height: 24px;
}

section.map .map {
	height: 512px;
}

@media only screen and (max-width: 640px) {

	section.events {
		display: flex;
		flex-direction: column;
	}

	section.events .list .activity .logo {
		flex-basis: 72px;
	}

	section.events .list .activity .logo img {
		max-width: 60px;
		max-height: 30px;
	}

	section.events .list h2 {
		font-size: 18px;
	}

	section.events .list h2 span {
		font-size: 14px;
	}

	section.events .list .activity .info p {
		font-size: 10px;
	}

	section.events .list .activity .info h6 {
		font-size: 12px;
	}

	section.events .list .activity .info h6 img {
		margin-right: 8px;
	}

	section.events .list .activity[data-v-fae5bece] {
		padding: 8px 0;
	}

	section.events .list .more {
		top: 30px;
	}

	section.map {
		padding: 24px 0 0 0;
	}

	section.map h1 {
		font-size: 24px;
	}

	section.map p {
		font-size: 12px;
	}

	section.map .map {
		height: 320px;
	}
}

</style>

<style lang="scss">

$link: #333;
$hover: #e74c3c;

.gmaps-info {
	display: flex;
}

.gmaps-info .picture {
	flex: 0 0 80px;
}

.gmaps-info .content {
	flex: 1 0 auto;
	padding-left: 12px;
	text-align: left;
}

.gmaps-info .content h2 {
	margin-top: 0;
}

.gmaps-info .content p {
	margin: 0 0 6px 0;
}

.gmaps-info .content p img {
	vertical-align: middle;
}

.gmaps-info .content a {
	color: $link;
	text-decoration: none;
}

.gmaps-info .content a:hover {
	color: $hover;
}

</style>
